<template>
  <div class="form">
    <el-form :inline="true" :model="search" ref="search" :rules="rules">
      <el-form-item prop="name">
        <el-input v-model.trim="search.name" clearable placeholder="必填">
          <template slot="prepend"><span class="required">姓名</span></template>
        </el-input>
      </el-form-item>
      <el-form-item prop="idcard">
        <el-input v-model.trim="search.idcard" class="id-card" clearable placeholder="必填">
          <template slot="prepend"><span class="required">身份证号</span></template>
        </el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input v-model="search.mobile" clearable placeholder="非必填">
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <!-- <el-form-item prop="factor">
        <el-select v-model="search.factor">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button class="query-btn" @click="query">查询</el-button>
        <el-button type="primary" @click="$emit('search', search)">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import verify from '@/utils/verify';
export default {
  data () {
    var checkIdCard = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入身份证号'));
      } else if (!verify.idCard(value)) {
        callback(new Error('请输入正确的身份证号'));
      }
      callback();
    };
    return {
      search: { name: '', mobile: '', idcard: null, factor: 2 },
      /***
       * 为了搜索填入信息不自动触发校验
       * trigger只有'blur'和'change'两个值, 去除trigger还是会触发自动校验，
       * 暂时未找到禁用自动触发校验的方法
       * 只能将trigger 由 blur 改为 change，避开校验
       */
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'change' }],
        // mobile: [
        //   { required: true, message: '请输入手机号', trigger: 'change' },
        //   { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确格式的手机号', trigger: 'change' }
        // ],
        idcard: [{ required: true, validator: checkIdCard, trigger: 'change' }]
      },
      options: [
        { value: 0, label: '关闭校验' },
        { value: 1, label: '二要素校验' },
        { value: 2, label: '三要素校验' }
      ]
    };
  },
  methods: {
    query () {
      let check = false;
      this.$refs['search'].validate(res => (check = res));
      if (!check) return;
      if (this.search.mobile != '') {
        this.search.factor = this.options[2].value
      } else {
        this.search.factor = this.options[1].value
      }
      this.$emit('query', this.search);
    },
    //清空
    clear () {
      this.$refs.search.fields[0].resetField()
      this.$refs.search.fields[1].resetField()
      this.$refs.search.fields[2].resetField()
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  margin-bottom: 20px;

  .id-card.el-input {
    width: 320px;
  }

  .query-btn {
    background-color: #1ecab8;
    border: 1px solid #1ecab8;
    color: #fff;
  }

  .required::before {
    content: "*";
    color: red;
    margin-left: 5px;
  }
}
</style>
